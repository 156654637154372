$('#datatable-product-set').dataTable({
  pageLength: 50,
  order: [],
});

$('#datatable-product-set-details').dataTable({
  pageLength: 50,
  order: []
});

$(function(){
  $('select.selectpicker-js').selectpicker();
  $('#product_set_details_form').on('cocoon:after-insert', function() {
    $('select.selectpicker-js').selectpicker();
  });
});
